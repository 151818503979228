@use '../../abstracts/' as *;
@use '../../abstracts/registerVariables' as rv;

.register-caption {
  $content-width: 450px;

  .caption-messages {
    display: block;
    margin: 0.5rem 0;
    padding: 0 1rem;
    @media (min-width: rv.$breakpoint-lg) {
      padding: 0;
    }
  }

  .image-caption {
    color: white;
    padding: 0;
    position: relative;

    .caption-image {
      display: none;
    }

    .caption-details {
      h2 {
        font-size: 1rem;
        margin-bottom: $margin-xs;
      }

      p {
        margin-bottom: $margin-xs;
      }
    }

    @media (min-width: rv.$breakpoint-lg) {
      .caption-image {
        display: flex;
        position: relative;
      }

      .caption-details {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 1.75rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        h2 {
          margin-bottom: 0.5rem;
          font-size: 2rem;
        }

        p {
          font-weight: bold;
        }
      }
    }
  }

  .text-caption {
    color: black;
    background-color: $color-teal-400;
    padding: 1.5rem 1rem 1rem 1rem;

    .caption-details {
      h2 {
        font-size: 1rem;
        margin-bottom: $margin-lg;
      }

      p {
        margin-bottom: $margin-xs;
      }
    }

    @media (min-width: rv.$breakpoint-lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: $content-width;
      background-color: $color-teal-400;
      color: white;
      padding: 4rem;

      .caption-details {
        h2 {
          font-size: 2rem;
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 0.9rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }
      }
    }
  }
}

.payment-caption {
  $content-width: 220px;

  .payment-caption-details {
    display: none;

    @media (min-width: rv.$breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: $content-width;
      background-color: $color-teal-400;
      color: white;
      padding: 1.75rem 1.25rem;
      width: $content-width;
      height: $content-width;
      border-radius: 4px;

      h2 {
        font-size: 2.1rem;
        margin-bottom: 1rem;
      }

      p {
        font-size: 0.95rem;
        font-weight: 800;
        margin-bottom: 0.25rem;
      }
    }
  }
}
