// Design System V1

// paddingDefaults
$padding-xs: 10px;
$padding-sm: 15px;
$padding-md: 20px;
$padding-lg: 30px;
$padding-xl: 40px;

// margin defaults
$margin-xs: 5px;
$margin-sm: 7.5px;
$margin-md: 10px;
$margin-lg: 15px;
$margin-xl: 20px;
