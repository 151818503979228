@import 'tokens.scss';

// =============================
// DEPRECATE ALL VARIABLES BELOW
// =============================

// DEPRECATE: Variables that were "current" as of Feb 2025
// Aliased to design tokens
$rivall-blue: $color-blue-700;
$rivall-blue-300: $color-blue-400;
$rivall-navy: $color-primary;
$rivall-dark: $color-primary;
$brick-red: $color-red-400;
$dusty-teal: $color-green-400;
$sun-yellow: $color-orange-400;
$tangerine: $color-orange-400;
$facebook: #3b5998;
$stripe: #5b66de;
$neon-yellow: $color-highlighter-300;
$neon-teal: $color-teal-300;
$light-teal: $color-teal-100;
$sky-blue: $color-blue-400;
$white: #fff;
$dropin: $color-program-dropin;
$grey-800: $color-grey-800;
$grey-600: $color-grey-600;
$grey-500: $color-grey-500;
$grey-400: $color-grey-300;
$grey-300: $color-grey-200;
$grey-200: $color-grey-100;
$grey-100: $color-grey-50;

// DEPRECATE: color variables from the /volo folder that are referenced by some components
$volo-primary: $color-primary;
$volo-primary-light: $color-blue-50;
$volo-accent-light: $color-highlighter-50;
$neutral-01: $color-grey-50;
$neutral-02: $color-grey-100;
$neutral-03: $color-grey-200;
$neutral-04: $color-grey-400;
$neutral-06: $color-grey-600;
$neutral-07: $color-grey-800;
$volo-secondary: $color-teal-300;
$volo-secondary-light: $color-teal-50;
$volo-accent: $color-highlighter-300;
$danger-dark: $color-red-900;
$success-dark: $color-green-900;
$warning-dark: $color-orange-900;
$link-dark: $color-blue-700;

// DEPRECATE: Aliased variables that were "legacy" even prior to Feb 2025
$darkish-neutral: $grey-800;
$med-neutral: $grey-500;
$lightish-neutral: $grey-400;
$white-neutral: $grey-100;
$rivall-dark-blue: $rivall-blue;
$rivall-yellow: $color-orange-400;
$rivall-black: $color-grey-1000;
$rivall-gray: $color-grey-600;
$rivall-map-blue: $color-blue-700;
$orange: $color-orange-400;
$legacy-white-neutral: $color-grey-50;
$neutral: $color-grey-500;
$lighter-neutral: $color-grey-100;
$light-neutral: $color-grey-200;
$legacy-lightish-neutral: $color-grey-400;
$legacy-med-neutral: $color-grey-800;
$legacy-darkish-neutral: $color-grey-1000;
$dark-neutral: $color-grey-1000;
$black-neutral: $color-grey-1000;
$main: $color-teal-300;
$pending: $color-grey-400;
$needs_approval: #e6701c;
$registration_open: #724bde;
$ready: #24567c;
$active: #17d817;
$light-trans-gray: rgba(230, 230, 230, 0.2);
$divider-gray: $color-grey-400;
