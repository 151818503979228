@use '../abstracts/' as *;
/*
DESIGN SYSTEM V1 UTILITY CLASSES
*/

// padding spacing defaults
.p-xs {
  padding: $padding-xs;
}

.p-sm {
  padding: $padding-sm;
}

.p-md {
  padding: $padding-md;
}
.p-lg {
  padding: $padding-lg;
}
.p-xl {
  padding: $padding-xl;
}

// margin spacing defaults
.m-xs {
  margin: $margin-xs;
}

.m-sm {
  margin: $margin-sm;
}

.m-md {
  margin: $margin-md;
}
.m-lg {
  margin: $margin-lg;
}
.m-xl {
  margin: $margin-xl;
}

// bg colors
.bg-primary {
  background-color: $color-primary;
}

.bg-accent-light {
  background-color: $color-highlighter-50;
}

.bg-light-grey {
  background-color: $color-grey-50;
}

// text colors
.text-volo-primary {
  color: $color-primary;
}
.text-volo-secondary {
  color: $color-teal-400;
}
.text-volo-accent {
  color: $color-highlighter-300;
}
.text-volo-grey {
  color: $color-grey-600;
}
.text-volo-danger {
  color: $color-red-900;
}
.text-volo-success {
  color: $color-green-900;
}
.text-volo-warning {
  color: $warning-dark;
}
.text-volo-link {
  color: $link-dark;
}

// text overflow
.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-hidden {
  overflow: hidden;
}

// box-shadow:
.default-shadow {
  box-shadow: 0px 0px 7px rgb(0, 0, 0, 0.15);
}

// borders {
.border {
  border: 1px solid $color-grey-600;
}

.border-light {
  border: 1px solid $color-grey-200;
}

.border-right {
  border-right: 1px solid $color-grey-600;
}

.border-left {
  border-left: 1px solid $color-grey-600;
}

.border-top {
  border-top: 1px solid $color-grey-600;
}

.border-bottom {
  border-bottom: 1px solid $color-grey-600;
}

.border-radius-1 {
  border-radius: 4px;
}

.border-radius-2 {
  border-radius: 8px;
}

// text styles
.bold {
  font-weight: bold;
}
.strikethrough {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

// misc
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  border-bottom: 1px solid #e5e5e5;
}
