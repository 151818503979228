@use '../../../../styles/variables' as *;
@use '../../../../styles/volo/abstracts/' as *;

.select-a-city {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3%;
  align-items: center;
  justify-content: center;
  margin-left: $discover-margin;
  margin-right: $discover-margin;

  h2 {
    font-size: 1rem;
    font-family: 'Open Sans';
    color: $color-primary;
  }

  .content {
    overflow-y: auto;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Open Sans';

    .card {
      align-items: center;
      background-color: $color-blue-50;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px $color-grey-400;
      color: $color-primary;
      display: flex;
      font-size: 0.55rem;
      height: 2.5rem;
      justify-content: center;
      margin: 0.5rem;
      padding: 5px;
      width: 15rem;
      border: 0;
      transition: all 0.5s;
      .cityName {
        text-align: center;
        font-weight: 600;
      }
      &:hover,
      &:focus {
        background: $color-primary;
        color: $color-blue-50;
        cursor: pointer;
        height: 4rem;
        opacity: 1;

        .cityName {
          color: $color-blue-50;
        }
      }
    }
  }

  @media (min-width: 575.98px) {
    .content {
      .card {
        width: 22rem;
        height: 3rem;
      }
    }
  }
}
