@import 'bootstrap/mixins';
@import 'bootstrap/functions';
@import 'colors.scss';
@import 'colors.sports.scss';
@import 'why-you-play.scss';
@import 'mixins.scss';
@import 'elevation.scss';

// THEME
$action: $color-action;
$primary: $color-primary;
$error: $color-danger;
$danger: $color-danger;
$success: $color-success;
$warning: $color-warning;

$btn-disabled-bg: $color-grey-100;
$btn-disabled-color: $color-grey-300;

$text-color: $color-grey-800;
$text-muted: $color-grey-300;

$border-color: $color-grey-100;
$bg-light: $color-grey-50;
$light: $color-grey-50;

$grey-input-color: $color-grey-50;
$input-border-color: $color-grey-300;
$input-disabled-color: $btn-disabled-color;
$input-disabled-bg: $btn-disabled-bg;
$input-placeholder-color: $text-muted;
$input-padding-y: 0.625rem;
$input-padding-x: 0.625rem;

$font-family:
  Arial,
  Helvetica Neue,
  Helvetica,
  sans-serif;

$max-container-width-player: 820px;

// Layout sizes
$navbar-height: 40px;
$navbar-padding: 10px;
$static-bar-width: 60px;
$menu-width: 180px;

$header-height: $navbar-height;

// Responsive thresholds. Deprecate in favor of bootstrap classes
$max-width: 1600px;
$max-content-width: 1400px;
$max-container-width: 1200px;
$tablet-width: 720px;
$mobile-width: 480px;

$manage-sidebar-width: 90px;

// have to import vars down here, otherwise bootstrap vars arent overwritten
@import 'bootstrap/variables';
