@use '../abstracts' as *;

.brand-logos-banner {
  h2 {
    color: $color-primary;
    font-size: 2rem;
    margin: 0.8em 0 !important; // Homepage styles have higher specificity, requiring the use of !important here.
  }

  .logo-bar {
    gap: 1em;
    flex-wrap: wrap;

    a {
      margin: 0;
    }

    img {
      height: 75px;
      margin: 0.3rem 1rem 0 0.5rem;
    }
  }
}
