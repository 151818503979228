@use '../abstracts/' as *;

@mixin btn($color: $color-primary, $text-color: #fff, $hover-color: $color-primary) {
  @include transition(0.2s);
  -webkit-appearance: none !important;
  align-items: center;
  border: 1px solid $color-grey-400;
  border-radius: 4px;
  color: $text-color;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: center;
  margin: 0 4px;
  min-height: 28px;
  outline: 0;
  text-align: center;
  g {
    fill: white;
  }
  &:hover {
    background-color: $hover-color;
    color: $color;
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    background-color: $btn-disabled-bg !important;
    border-color: $btn-disabled-bg;
    box-shadow: none !important;
    color: $btn-disabled-color !important;
    g {
      fill: $btn-disabled-color !important;
    }
  }

  &.rounded {
    border-radius: 30px;
  }

  @include media-breakpoint-down(sm) {
    min-width: 32px;
    margin: 0 2px;
  }
}

@mixin circle-btn($color: white, $height: 30px) {
  background-color: $color;
  height: $height;
  width: $height;
  border: 0;
  font-size: 12px;
  box-shadow: $default-shadow;
  z-index: 1;
  color: black;
  padding: 8px;

  &:hover {
    border: 0;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
    color: black;
  }
}

@mixin button-basics($color: $color-primary, $text-color: white, $border-color: $color-primary) {
  background-color: $color;
  border: 1px solid $border-color;
  border-radius: 4px;
  box-shadow: $default-shadow;
  color: $text-color;
  font-weight: 800;
  padding: 8px;
  text-transform: uppercase;

  &:hover {
    border: 0;
    color: black;
  }

  &.disabled {
    background-color: $color-grey-200;
  }
}

.primary-btn {
  @include button-basics($color-primary, $text-color: white);
}

.secondary-btn {
  @include button-basics(white, $text-color: $color-primary);
}

.plain-btn {
  margin: 0rem 0.25rem;
  &:hover {
    cursor: pointer;
    border: 0;
  }
}

.apply-button {
  color: inherit;
  font-weight: bold;
  padding: 0.3rem 0.5rem;
  border: 1px solid;
  border-radius: 4px;
}

.cancel-button {
  color: $color-red-900;
  margin-left: 0.5rem;
  text-decoration: underline;
}
