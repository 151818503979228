@use '../../abstracts/' as *;
@use '../../abstracts/registerVariables' as rv;

.register-progress-bar-container {
  $progress-bar-height: 85px;
  $content-width: 900px;
  $content-white-space: calc(100% - 910px);

  background-color: $color-teal-400;

  .register-progress-bar {
    background-color: $color-teal-400;
    display: none;
  }

  .register-mobile-progress-bar {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5rem;
    left: 0;
    right: 0;
    margin: 0 auto;

    .progress-dot {
      height: 8px;
      width: 8px;
      background-color: $color-grey-400;
      border-radius: 50%;
      display: inline-block;
      margin: 0 0.5rem;

      &.phase-current {
        position: relative;
        background-color: $color-teal-400;
        background-clip: content-box;
        border: solid 1px $color-teal-400;
        padding: 2px;
        height: 15px;
        width: 15px;
      }
    }
  }

  @media (min-width: rv.$breakpoint-md) {
  }

  @media (min-width: rv.$breakpoint-lg) {
    display: block;
    height: $progress-bar-height;

    .register-progress-bar {
      @include transition(3s);
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 calc($content-white-space / 2);
      position: relative;

      .bar-piece {
        width: calc($content-width / 4);
        height: 4px;
        background-color: $color-grey-300;
        font-size: 0.75rem;

        &.phase-current {
          &::before {
            content: url(../../../../images/icons/small-soccer-ball.svg);
            position: relative;
            top: -12px;
            left: -10px;
          }

          &::after {
            content: attr(datacontent);
            position: relative;
            left: -75px;
            top: 0;
            font-weight: bold;
            color: $color-primary;
            white-space: pre;
          }
        }

        &.phase-complete {
          background-color: $color-primary;

          &::before {
            content: url(../../../../images/icons/blue-checkmark.svg);
            position: relative;
            top: -6px;
          }

          &::after {
            content: attr(datacontent);
            position: relative;
            left: -55px;
            top: 14px;
            font-weight: normal;
            color: $color-primary;
            white-space: pre;
          }
        }

        &.phase-future {
          &::before {
            content: url(../../../../images/icons/empty-circle.svg);
            position: relative;
            top: -8px;
          }

          &::after {
            content: attr(datacontent);
            position: relative;
            top: 10px;
            left: -60px;
            white-space: pre;
          }
        }
      }

      .goal {
        position: absolute;
        top: -11px;
        right: -50px;
        color: $color-grey-800;

        .ball {
          display: none;
        }

        &::before {
          content: url(../../../../images/icons/net.svg);
          position: absolute;
          top: -8px;
          right: 46px;
        }

        &::after {
          content: 'Success!';
          position: relative;
          font-size: 0.75rem;
          top: 34px;
          right: 40px;
        }

        &.phase-current {
          .ball {
            display: unset;
          }
          &::before {
            top: -8px;
            right: 45px;
          }
          &::after {
            color: $color-primary;
            font-weight: bold;
          }
        }
      }
    }

    .register-mobile-progress-bar {
      display: none;
    }
  }
}
